import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "~components/layout"
import Section from "~components/global/section"
import Slider from "~components/global/slider"
import ProductBadgeMini from "~components/global/productBadgeMini"
import Button from "~components/global/button"
import { useCart } from "~context/siteContext"
import RenderModule from "~components/renderModule"
import SEO from "~components/seo"
import { dlSelectItem } from "../utils/dlFunctions"

const ExtraThumb = ({ title, tagline, variants, product }) => {
  const [currentVar, setCurrentVar] = useState(0)
  const [interacted, setInteracted] = useState(false)

  const { addItem } = useCart()

  const getImage = (v, i) => {
    if(v.content.main.basic
      && v.content.main.basic.productImages.length
      && v.content.main.basic.productImages[0]
      && v.content.main.basic.productImages[0].asset
    ){
      return v.content.main.basic.productImages[0].asset.fluid
    }
    else if(i === 0
      && product.content.main.basic
      && product.content.main.basic.productImages.length
      && product.content.main.basic.productImages[0]
      && product.content.main.basic.productImages[0].asset
    ){
      return product.content.main.basic.productImages[0].asset.fluid
    }
    return null
  }

  const cartVariants = variants.map((v, i) => (
    {
      vId: v.content._rawShopify.variantId,
      title: v.content._rawShopify.title,
      subTitle: v.content._rawShopify.variantTitle,
      fluid: getImage(v,i),
      subscribe: false,
      price: v.content._rawShopify.price,
      subPrice: null,
      qty: 1,
    }
  ))

  const slugs = variants.map(v => v.content.main.slug.current)

  function updateCurrent(c){
    setCurrentVar(c)
    setInteracted(true)
  }

  function addToCart(){
    addItem(cartVariants[currentVar], true)
  }

  return(
    <div className={`extra-thumb ${interacted ? 'extra-thumb--interacted' : ''}`}>
      <div className="extra-thumb__images">
        <Link to={`/extras/${product.content.main.slug.current}/${slugs[currentVar]}`} onClick={() => dlSelectItem(product?.content, currentVar + 1, 'Extras', variants[currentVar])}>
          <Slider setSlide={currentVar}>
            {variants.map((v, i) => (
              <div className="extra-thumb__slide">
                <Img fluid={getImage(v,i)} />
              </div>
            ))}
          </Slider>
        </Link>
      </div>
      <h3 className="m-caps">{title}</h3>
      <h4 className="s-serif">{tagline}</h4>
      <div className="extra-thumb__variants">
        {variants.map((v, i) => (
          <button onClick={() => updateCurrent(i)} style={{transform: `scale(${interacted && i === currentVar ? '1.2' : '1'})`}}>
            <ProductBadgeMini colors={v.content.main.basic ? v.content.main.basic.iconColors.map(c => c.color ? c.color.hex : 'null') : []} />
          </button>
        ))}
      </div>
      <div className="extra-thumb__view">
        <Button to={`/extras/${product.content.main.slug.current}`} trackingEvent={() => dlSelectItem(product?.content, currentVar + 1, 'Extras', variants[currentVar])}>
          View Product
        </Button>
      </div>
      <div className="extra-thumb__add">
        <Button onClick={() => addToCart()}>
          Add to Parcel
        </Button>
      </div>
    </div>
  )
}

const Extras = ({ data }) => {

  const createImpressions = products => {

    const impressions = products.map((product, i) => 
      product?.content?.main?.variants.map(variant => ({
        id: variant?.content?._rawShopify?.sku, // SKU
        name: product?.content?._rawShopify?.title, // Product title
        brand: "Unknown",
        category: "Unknown",
        variant: variant?.content?._rawShopify?.variantTitle,
        price: variant?.content?._rawShopify?.price,
        list: window.location.pathname, // Not required for dl_view_cart; The list the product is displayed in
        product_id:  variant?.content?._rawShopify?.productId?.toString(), // The product_id
        variant_id: variant?.content?._rawShopify?.variantId?.toString(), // id or variant_id
        // position: i // position in the list of search results, collection views and position in cart indexed starting at 1
      })
    ))

    return impressions.flat().map((item, i) => ({...item, position: i}))
  }
  
  useEffect(() => {
    window.ElevarDataLayer.push({
      event: "dl_view_item_list",
      user_properties: {
        visitor_type: "guest",
      },
      ecommerce: {
        currencyCode: "AUD",
        impressions: createImpressions(data.allSanityExtra.nodes)
      }
    })
    // console.log({impressions: createImpressions(data.allSanityExtra.nodes)})
  }, [])

  return(
    <>
    <SEO metaInfo={data.sanityExtrasIndex._rawMeta} pagePath='/extras' />
    <Layout>
      <Section name="extras-title">
        <h1 className="l-sans">{data.sanityExtrasIndex.title}</h1>
      </Section>
      <Section name="extras-grid">
        {data.allSanityExtra.nodes.map(product => (
          <ExtraThumb title={product.content.main.title} tagline={product.content.main.basic && product.content.main.basic.tagline} variants={product.content.main.variants} product={product} />
        ))}
      </Section>
      {data.sanityExtrasIndex.modules && data.sanityExtrasIndex.modules.map(m => (
        <RenderModule
          key={m._key}
          type={m._type}
          m={m}
          />
      ))}
    </Layout>
    </>
  )
}

export const query = graphql`
query{
  sanityExtrasIndex(id: {eq: "-f9b5bbdc-67c3-5cd9-8612-10db125e50fb"}) {
    _rawMeta(resolveReferences: {maxDepth: 2})
    title
    modules {
      ... on SanityFaqs {
          _key
          _type
          title
          questions {
            _rawAnswer
            question
            _key
          }
        }
      ... on SanityTile {
          _key
          _type
          _rawText(resolveReferences: {maxDepth: 4})
          image {
            asset {
              _id
              url
              fluid {
                aspectRatio
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
          cta {
            ... on SanityInternalLink {
              _key
              _type
              _rawTo(resolveReferences: {maxDepth: 6})
              linkText
            }
            ... on SanityExternalLink {
              _key
              _type
              url
              linkText
            }
          }
          order
          title
        }
      ... on SanityHeadingText {
          _key
          _type
          _rawText
          title
        }
      ... on SanityTextIllo {
          _key
          _type
          _rawCta(resolveReferences: {maxDepth: 8})
          backgroundType
          _rawHeading
          illo {
            asset {
              fluid {
                aspectRatio
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
        }
      ... on SanityProductSlider {
          _key
          _type
          slides {
            image {
              asset {
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
            }
            _rawLink(resolveReferences: {maxDepth: 4})
            subtitle
            text
            title
            buttonText
          }
          title
        }
      ... on SanityReviewsGrid {
        _key
        _type
        reviews {
          link
          name
          platform
          rating
          username
          _rawReview
          image {
            asset {
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
        }
        subtitle
        title
      }
      ... on SanityGroupGrid {
        _key
        _type
        title
        groups {
          group {
            slug {
              current
            }
            _type
          }
          buttonText
          subtitle
          title
          image {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
                sizes
                base64
                aspectRatio
              }
            }
          }
        }
      }
      ... on SanityDivider {
          _key
          _type
          width
        }
      ... on SanityIconRow {
        _key
        _type
        icons {
          text
          title
          image {
            asset {
              fluid {
                aspectRatio
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
        }
      }
      ... on SanityCenteredText {
        _key
        _rawText
        _type
        title
        image {
          asset {
            fluid {
              aspectRatio
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
      }
      ... on SanityTicker {
        _key
        _type
        highlightColor {
          hex
        }
        _rawText(resolveReferences: {maxDepth: 10})
        background {
          hex
        }
        textColor {
          hex
        }
        _rawLink(resolveReferences: {maxDepth: 6})
      }
      ... on SanityFullWidth {
        _key
        _type
        bgImage {
          asset {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
        bgImagePortrait {
          asset {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
        bgVideoLandscape {
          asset {
            url
          }
        }
        bgVideoPortrait {
          asset {
            url
          }
        }
        buttonColor
        buttonText
        buttonType
        subtitle
        title
      }
      ... on SanityTextImage {
        _key
        _type
        imageFit
        textBackground
        textAlign
        order
        _rawText(resolveReferences: {maxDepth: 10})
        imageBackground {
          hex
        }
        video {
          asset {
            url
          }
        }
        image {
          _key
          asset {
            _id
            url
            fluid(maxWidth: 2400) {
              srcWebp
              srcSetWebp
              srcSet
              src
              sizes
              base64
              aspectRatio
            }
          }
        }
      }
    }
  }
  allSanityExtra {
    nodes {
      content {
        main {
          basic {
            tagline
            productImages {
              asset {
                fluid {
                  srcWebp
                  srcSetWebp
                  srcSet
                  src
                  sizes
                  base64
                  aspectRatio
                }
              }
            }
          }
          title
          slug {
            current
          }
          variants {
            content {
              main {
                title
                slug {
                  current
                }
                basic {
                  iconColors {
                    color {
                      hex
                    }
                  }
                  productImages {
                    asset {
                      fluid {
                        srcWebp
                        srcSetWebp
                        srcSet
                        src
                        sizes
                        base64
                        aspectRatio
                      }
                    }
                  }
                }
              }
              _rawShopify
            }
          }
        }
        _rawShopify
      }
    }
  }
}
`

export default Extras
